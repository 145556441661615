export const getEnvironment = () => {
    const host = window.location.host;
    let environment = 'local';

    switch (host) {
        case 'localhost:3000':
            environment = 'local';
            break;
        case 'spear-dev.service-management.bosch.tech':
            environment = 'dev';
            break;
        case 'spear-int.service-management.bosch.tech':
            environment = 'int';
            break;
        case 'spear-qas.service-management.bosch.tech':
            environment = 'qas';
            break;
        case 'spear.service-management.bosch.tech':
            environment = 'prod';
            break;
        default:
            environment = 'none';
            break;
    }

    return environment;
};
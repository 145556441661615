import React, { useEffect, useState } from 'react';
import RateCloseTicketsModal from '../../Table/ModalsTickets/RateCloseTicketsModal';
import { encodeHash } from '../../../../utils/boschEncodeHash';
import { useTranslation } from 'react-i18next';

const RateCell = (props) => {
  const { t } = useTranslation();
  const { source, incidentId, available, name, userInfo } = props;
  const [itspUrl, setItspUrl] = useState('');
  const [label, setLabel] = useState({
    label: '',
    localizedLabel: ''
  });
  const [icon, setIcon] = useState('');

  useEffect(() => {
    getSurveyUrl();
  }, [props]);

  //For close closed table
  //Determine whether to generate feedback url or not based on source, incidentId, available
  //Input: source, incidentId, available
  //Output: Survey url is it is within 14 days.
  const getSurveyUrl = () => {
    if (typeof available !== 'undefined') {
      let tempUrl = '';
      //Determine type
      let type = source;
      //Get Closed date in milisecond
      let closedDate = new Date(available);
      //Get Current date
      let todayDate = new Date();
      //Calculated difference between close date and today
      let feedBackWindow = Math.floor(
        (todayDate - closedDate) / (1000 * 60 * 60 * 24)
      );
      //if close date is wihin 21 days, display those data.
      if (feedBackWindow <= 21) {
        setIcon('boschicon-bosch-ic-wishlist');
        setLabel({
          ...label,
          label: 'Rate',
          localizedLabel: t('ui_spear.myTickets.Rate')
        });
        //check if CPSC(ITSP)
        /* To do para crear la url de el iframe de los tickets de CPSC y de serviNow
         * Necesito consultar un JSP, para encodear el insidenteId y este se manda como parametro en la URL*/
        if (type.toUpperCase() === 'CPSC' || type.toUpperCase() === 'SN') {
          tempUrl =
            'https://survey.bosch.com/cgi-bin/s.app?A=MIxbkBXc&T-TRID=' +
            encodeURI(name) +
            '&EXTID=' +
            incidentId +
            '&EXTCHK=' +
            encodeHash(incidentId);
          setItspUrl(tempUrl);
        } else if (type.toUpperCase() === 'SMT') {
          tempUrl = 'https://survey.bosch.com/cgi-bin/s.app?A=x8ZVsyh3';
          setItspUrl(tempUrl);
          //#psc-feedback-modal
        }
      } else {
        setIcon('boschicon-bosch-ic-clock');
        setLabel({
          ...label,
          label: 'Expired',
          localizedLabel: t('ui_spear.myTickets.Expired')
        });
      }
    }
  };
  return (
    <>
      <RateCloseTicketsModal
        sys_cat_id={props.cat_sys_id}
        serviceName={name}
        icon={icon}
        label={label}
        itspUrl={itspUrl}
        source={source}
        incidentId={incidentId}
        userInfo={userInfo}
      />
    </>
  );
};

export default RateCell;

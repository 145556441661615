const HELP_DESK_VIP = {
    0: 'Yes',
    1: 'No'
}

const HELP_DESK_STATUS = {
    0: 'New',
    1: 'Assigned',
    2: 'In Progress',
    3: 'Pending',
    4: 'Resolved',
    5: 'Closed',
    6: 'Cancelled',
}

const HELP_DESK_STATUS_REASON = {
    2000: 'Local Site Action Required',
    3000: 'Purchase Order Approval',
    4000: 'Registration Approval',
    5000: 'Supplier Delivery',
    6000: 'Support Contact Hold',
    7000: 'Third Party Vendor Action Reqd',
    8000: 'Client Action Required',
    9000: 'Infrastructure Change',
    10000: 'Request',
    11000: 'Future Enhancement',
    12000: 'Pending Original Incident',
    13000: 'Client Hold',
    14000: 'Monitoring Incident',
    15000: 'Customer Follow-Up Required',
    16000: 'Temporary Corrective Action',
    17000: 'No Further Action Required',
    18000: 'Resolved by Original Incident',
    19000: 'Automated Resolution Reported',
    23000: 'Business Case Rejected',
}

const HELP_DESK_PRIORITY = {
    0: 'Critical',
    1: 'High',
    2: 'Medium',
    3: 'Low'
}

const HELP_DESK_URGENCY = {
    1000: '1-Critical',
    2000: '2-High',
    3000: '3-Medium',
    4000: '4-Low'
}

const HELP_DESK_IMPACT = {
    1000: '1-Extensive/Widespread',
    2000: '2-Significant/Large',
    3000: '3-Moderate/Limited',
    4000: '4-Minor/Localized'
}

const HELP_DESK_INCIDENT_TYPE = {
    0: 'User Service Restoration',
    1: 'User Service Request',
    2: 'Infrastructure Restoration',
    3: 'Infrastructure Event'
}

const HELP_DESK_REPORTED_SOURCE = {
    1000: 'Direct Input',
    2000: 'Email',
    2200: 'Chat',
    3000: 'External Escalation',
    4000: 'Fax',
    4200: 'Self Service',
    5000: 'Systems Management',
    6000: 'Phone',
    7000: 'Voice Mail',
    8000: 'Walk In',
    9000: 'Web',
    10000: 'Other',
    11000: 'BMC Impact Manager Event',
}

const WORKLOG_TYPES = {
    1000: '----- Customer Inbound -----',
    2000: 'Customer Communication',
    3000: 'Customer Follow-up',
    4000: 'Customer Status Update',
    5000: '----- Customer Outbound -----',
    6000: 'Closure Follow Up',
    7000: 'Detail Clarification',
    8000: 'General Information',
    9000: 'Resolution Communications',
    10000: 'Satisfaction Survey',
    11000: 'Status Update',
    12000: '----- General -----',
    13000: 'Incident Task / Action',
    14000: 'Problem Script',
    15000: 'Working Log',
    16000: 'Email System',
    17000: 'Paging System',
    18000: 'BMC Impact Manager Update',
    35000: 'Chat',
    36000: '----- Vendor -----',
    37000: 'Vendor Communication',
}

const ASSIGNED_GRP_PERSON_ROLE = {
    10000: 'Supported by',
    39000: 'Managed by',
    6000: 'Used by'
}

export {
    HELP_DESK_VIP,
    HELP_DESK_STATUS,
    HELP_DESK_STATUS_REASON,
    HELP_DESK_PRIORITY,
    HELP_DESK_URGENCY,
    HELP_DESK_IMPACT,
    HELP_DESK_INCIDENT_TYPE,
    HELP_DESK_REPORTED_SOURCE,
    WORKLOG_TYPES,
    ASSIGNED_GRP_PERSON_ROLE
};
import React from 'react';
import { useNavigate } from 'react-router-dom';
import StatusCell from '../StatusCell';
import { formatSnowDate } from '../../../../utils/snDateFormatting';
import { handleTicketNameFormat } from '../../../../utils/snFormatting';

const OpenRow = ({
  ticketId,
  name,
  solution,
  availableUntil,
  type,
  source,
  rate,
  bgRow,
  reqEntryId,
  serviceId,
  person,
  closed,
  submittedDate,
  orderGuideName
}) => {
  let navigate = useNavigate();

  const goTicketDetails = () => {
    navigate(`/tickets/ticket-journey/${ticketId}`);
  };

  return (
    <tr
      className={`cursor-pointer border-b hover:bg-gray-100 ${bgRow}`}
      onClick={goTicketDetails}
    >
      <td className="w-[14%] px-6 py-3 text-left">
        <a href={`/tickets/ticket-journey/${ticketId}`}>{ticketId}</a>
      </td>
      <td className="w-[32%] px-6 py-3 text-left">
        {handleTicketNameFormat(orderGuideName, name)}
      </td>
      <td className="w-[13%] px-6 py-0 text-left">
        <StatusCell
          source={source}
          incidentId={ticketId}
          reqEntryId={reqEntryId}
          serviceId={serviceId}
          status={solution}
        />
      </td>
      <td className="w-[12%] px-6 py-3 text-left">
        {formatSnowDate(source, submittedDate)}
      </td>
      <td className="w-[13%] px-6 py-3 text-left">{availableUntil}</td>
      <td className="w-[13%] px-6 py-3 text-left">{person}</td>
      <td className="w-[13%] px-6 py-3 text-left">{closed}</td>
    </tr>
  );
};

export default OpenRow;
